<template>
  <div class="my-16">
    <v-container>
      <v-row>
        <v-col cols="12" md="8" offset-md="2">
          <h2 class="h2 -sci-gradient">{{ title }}</h2>
          <MarkdownBlock :text="subtitle"/>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="modular-video pa-0">
      <v-row no-gutters>
        <v-col cols="12" md="8" offset-md="2">
          <VideoPlayer
            :video="video"
            :poster="poster"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { MarkdownBlock } from '@monogrid/vue-lib'
import VideoPlayer from '@/molecules/VideoPlayer.vue'

export default {
  name: 'ModularVideoTutorial',
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: true
    },
    video: {
      type: [String, Object],
      required: true
    },
    poster: {
      type: String,
      required: false
    }
  },
  components: {
    VideoPlayer,
    MarkdownBlock
  }
}
</script>

<style lang="scss" scoped>
.modular-video {
  margin-top: rem(36x);
  margin-bottom: rem(160px);
}
</style>
