<template>
  <div>
    <h4
      v-if="title"
      class="h4 mb-2"
    >
      {{ title }}
    </h4>
    <Cta
      v-for="(attachment, index) in attachments"
      :key="index"
      :label="attachment.label"
      :download="{ title: attachment.label, file: attachment.file.id }"
      action="download"
      class="d-block"
    />
  </div>
</template>

<script>
import Cta from '@/molecules/Cta.vue'

export default {
  name: 'ModularAttachments',
  components: { Cta },
  props: {
    title: {
      type: String,
      required: false
    },
    attachments: {
      type: Array,
      required: true
    }
  }
}
</script>
