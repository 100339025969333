<template>
  <div>
    <div
      v-for="(content, index) in contents"
      :key="index"
      class="modular-section-content"
    >
      <ModularTitleText
        v-if="content._repeaterType === 'Title and text'"
        v-bind="content"
      />
      <ModularStringList
        v-if="content._repeaterType === 'Title and list'"
        v-bind="content"
      />
      <ModularColorVariants
        v-if="content._repeaterType === 'Available colors' && colors"
        v-bind="content"
        :title="content.title"
        :colors="colors"
      />
      <ModularAttachments
        v-if="content._repeaterType === 'Attachments'"
        v-bind="content"
      />
      <ModularInfographics
        v-if="content._repeaterType === 'Infographics'"
        v-bind="content"
      />
    </div>
  </div>
</template>

<script>
import ModularTitleText from './modular_content/ModularTitleAndText.vue'
import ModularStringList from './modular_content/ModularStringList.vue'
import ModularColorVariants from './modular_content/ModularColorVariants.vue'
import ModularAttachments from './modular_content/ModularAttachments.vue'
import ModularInfographics from '@/molecules/modular_content/ModularInfographics.vue'

export default {
  name: 'ModularContent',
  components: {
    ModularTitleText,
    ModularStringList,
    ModularColorVariants,
    ModularAttachments,
    ModularInfographics
  },
  props: {
    contents: {
      type: Array,
      required: true
    },
    colors: {
      type: Array,
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
.modular-section-content {
  margin: rem(36px) 0;
}
</style>
