<template>
  <div>
    <h4
      v-if="title"
      class="h4"
    >
      {{ title }}
    </h4>
    <div class="d-flex flex-wrap mt-4">
      <div
        v-for="(infographic, index) in infographics"
        :key="index"
        class="icon-container"
      >
        <v-img
          width="100%"
          height="auto"
          :lazy-src="getLazySrc(infographic)"
          :src="optimizedImageURL(infographic, { quality: 100 })"
          :aspect-ratio="getAspectRatio(infographic)"
          class="d-inline-block"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { getLazySrc, optimizedImageURL, getAspectRatio } from '@/services/OptimizedImage'

export default {
  name: 'ModularInfographics',
  props: {
    title: {
      type: String,
      required: false
    },
    infographics: {
      type: Array,
      required: true
    }
  },
  methods: {
    getLazySrc,
    optimizedImageURL,
    getAspectRatio
  }
}
</script>

<style lang="scss" scoped>
.icon-container {
  width: 50%;
  padding: 0 2%;
  @include breakpoint('sm-and-up') {
    // max-width: rem(160px);
    width: 33%;
  }
}
</style>
