<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" sm="8" offset-sm="2">
          <div class="d-flex align-center justify-space-between">
            <h2 class="h2 -sci-gradient">{{ title }}</h2>
            <SliderArrows
              class="slider-arrow"
              :nextDisabled="nextDisabled"
              :prevDisabled="prevDisabled"
              v-show="showArrow"
              @prev="slidePrev()"
              @next="slideNext()"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="custom-swiper-container px-0 pb-16">
      <v-row no-gutters>
        <v-col cols="12">
          <div ref="swiper" class="swiper-container -above">
            <div class="swiper-wrapper">
              <div
                  v-for="(slide, i) in slides"
                  class="swiper-slide px-1 px-md-2"
                  :key="i"
                  >
                <Card
                  v-bind="slide"
                  :type="slide.__typename"
                  :cursor="slides.length > 6 ? 'inherit' : 'pointer'"
                />
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { Swiper } from 'swiper'
import SliderArrows from '@/atoms/SliderArrows.vue'
import Card from '@/atoms/Card.vue'

export default {
  name: 'CardCarousel',
  props: {
    title: {
      type: String,
      required: true
    },
    slides: {
      type: Array,
      required: true
    }
  },
  components: {
    SliderArrows,
    Card
  },
  data: () => ({
    showArrow: true,
    prevDisabled: true,
    nextDisabled: false
  }),
  mounted () {
    this.$nextTick(() => {
      this.swiper = new Swiper(this.$refs.swiper, {
        centeredSlides: false,
        freeMode: true,
        freeModeSticky: true,
        touchStartPreventDefault: false,
        watchSlidesProgress: true,
        watchOverflow: true,
        allowTouchMove: false,
        centerInsufficientSlides: true,
        grabCursor: this.slides.length > 6,
        on: {
          init: this.onResize,
          resize: this.onResize,
          reachBeginning: () => {
            this.prevDisabled = true
            this.nextDisabled = false
          },
          reachEnd: () => {
            this.prevDisabled = false
            this.nextDisabled = true
          },
          fromEdge: this.fromEdge,
          toEdge: this.toEdge
        },
        breakpoints: {
          // when window width is >= 0px
          0: {
            slidesPerView: 2
          },
          // when window width is >= 600px
          600: {
            slidesPerView: 3
          },
          // when window width is >= 960px
          960: {
            slidesPerView: 4
          },
          // when window width is >= 1264px
          1264: {
            slidesPerView: 5
          }
        }
      })
    })
  },
  beforeDestroy () {
    this.swiper && this.swiper.destroy(true, false)
  },
  methods: {
    onResize (swiper) {
      if (swiper.isEnd && swiper.isBeginning) {
        this.showArrow = false
        swiper.allowTouchMove = false
        swiper.grabCursor = false
      } else {
        this.showArrow = true
        swiper.allowTouchMove = true
        swiper.grabCursor = true
      }
    },
    fromEdge (swiper) {
      if (!swiper.isBeginning && !swiper.isEnd) {
        this.prevDisabled = false
        this.nextDisabled = false
      }
    },
    toEdge (swiper) {
      if (swiper.isBeginning) {
        this.prevDisabled = true
      }
      if (swiper.isEnd) {
        this.nextDisabled = true
      }
    },
    slidePrev () {
      this.swiper && this.swiper.slidePrev()
    },
    slideNext () {
      this.swiper && this.swiper.slideNext()
    }
  }
}
</script>

<style lang="scss" scoped>
.slider-arrow {
  width: rem(170px);
  padding-left: rem(48px);
}

.swiper-slide {
  box-sizing: border-box;
}

.custom-swiper-container {
  margin-top: rem(72px);
  @include breakpoint ('sm-and-down') {
    margin-top: rem(28px);
  }
}
</style>
