<template>
  <div>
    <h4
      v-if="title"
      class="h4"
    >
      {{ title }}
    </h4>
    <div class="d-flex flex-wrap mt-4">
      <div
        v-for="(color, index) in colors"
        :key="index"
        class="color mr-2 mb-2"
      >
        <div
          :style="{
            backgroundImage: color.image ? `url(${optimizedImageURL(color.image, {width: 30})})` : null,
            backgroundColor: `#${color.hexCode}`,
            backgroundSize: 'cover'
          }"
          class="circle mb-2 mx-auto"
        >
        </div>
        <div class="text-small text-center">{{ color.name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { optimizedImageURL } from '@/services/OptimizedImage'

export default {
  name: 'ModularColorVariants',
  props: {
    title: {
      type: String,
      required: false
    },
    colors: {
      type: Array,
      required: true
    }
  },
  methods: {
    optimizedImageURL
  }
}
</script>

<style lang="scss" scoped>
.color {
  width: rem(60px);
  min-width: rem(60px);
  flex: 0 1 auto;
}

.circle {
  width: rem(32px);
  height: rem(32px);
  border-radius: 50%;
}
</style>
