<template>
  <div>
    <h4
      v-if="title"
      class="h4"
    >
      {{ title }}
    </h4>
    <div
      v-for="(string, index) in strings"
      :key="index"
    >
      <div>{{ string }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModularStringList',
  props: {
    title: {
      type: String,
      required: false
    },
    strings: {
      type: Array,
      required: true
    }
  }
}
</script>
